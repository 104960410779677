body {
    font-family: 'Karla', sans-serif;
}

.navbar-light {
    border-bottom: solid 1px #e9ecf4;

    .navbar-brand {
        img {
            height: 25px;
        }
    }

    .navbar-nav {
        .nav-link {
            padding: 30px 15px;
            font-size: 16px;
            font-weight: bold;
            color: #030303;
        }
    }
}

main {
    min-height: calc(100vh - 102px);
}


/* Wizard */

.wizard {

    .audible {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }

    .steps {

        padding-top: 30px;
        padding-bottom: 30px;

        &>ul {
            list-style: none;
            padding-left: 0;
            display: flex;
            justify-content: space-between;
            background-image: linear-gradient(#7b7f89, #7b7f89);
            background-size: calc(100% - 50px) 4px;
            background-repeat: no-repeat;
            background-position: center;

            li {
                a {
                    display: inline-block;
                }

                span.number {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    background-color: #7b7f89;
                    text-align: center;
                    line-height: 50px;
                    color: #fff;
                }

                &.current,
                &.done {
                    span.number {
                        background-color: #00d69f;
                    }
                }
            }
        }
    }

    .content {
        margin-bottom: 45px;

        .title {
            display: none;
        }

        .bd-wizard-step-title {
            font-size: 20px;
            font-weight: bold;
            color: #7b7f89;
            margin-bottom: 45px;
        }

        .section-heading {
            font-size: 35px;
            font-weight: bold;
            color: #030303;
            margin-bottom: 17px;
        }

        p {
            font-size: 16px;
            color: #030303
        }

        .purpose-radio {
            position: relative;
            display: inline-block;

            .purpose-radio-input {
                position: absolute;
                opacity: 0;

                &:checked+.purpose-radio-label {
                    border-color: #00d69f;

                    .label-icon {
                        color: #00d69f;

                        .label-icon-default {
                            display: none;
                        }

                        .label-icon-active {
                            display: inline-block;
                        }
                    }

                    .label-text {
                        color: #00d69f;
                        font-weight: bold;
                    }
                }
            }

            .purpose-radio-label {
                display: flex;
                width: 196px;
                height: 184px;
                max-width: 100%;
                border: 2px solid #7b7f89;
                border-radius: 4px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all 0.2s ease-in-out;
                cursor: pointer;

                @media (max-width: 575px) {
                    width: 100%;
                }

                .label-icon {
                    font-size: 21px;
                    color: #000000;
                    margin-bottom: 17px;
                    transition: all 0.2s ease-in-out;

                    .label-icon-active {
                        display: none;
                    }
                }

                .label-text {
                    font-size: 16px;
                    color: #030303;
                    transition: all 0.2s ease-in-out;
                }
            }
        }

        .purpose-radios-wrapper {
            margin-top: 60px;

            .purpose-radio {
                margin-right: 18px;
                max-width: calc( 33% - 18px);

                @media (max-width: 575px) {
                    margin-right: 0;
                    margin-bottom: 18px;
                    width: 100%;
                    max-width: none;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .form-control {
            padding: 26px 25px;
            min-height: 50px;
            max-width: 550px;
            border-radius: 4px;
            border: solid 1px #ececec;

            &::placeholder {
                color: #919aa3;
            }
        }
    }

    .actions {

        &>ul {
            list-style: none;
            padding-left: 0;
            display: flex;
        }

        li {
            a {
                display: inline-block;
                border-radius: 6px;
                background-color: #00d69f;
                padding: 16px 30px;
                color: #fff;
                font-style: 15px;
                font-weight: bold;
            }

            &.disabled {
                display: none;
            }

            &:not(.disabled)+li,
            &:not(:first-child):last-child {
                margin-left: 15px;
            }
        }
    }

    &.vertical {
        @media (min-width: 768px) {
            padding-left: 200px;
            position: relative;
            min-height: 450px; 

            .steps {
                padding-top: 0;
                padding-bottom: 0;
                min-height: 480px;
                position: absolute;
                left: 0;
                display: flex;
    
                &>ul {
                    width: 50px;
                    flex-direction: column;
                    background-size: 4px calc(100% - 50px);
                    margin-bottom: 0;
    
                    li:not(:last-child) {
                        margin-bottom: 24px;
                    }
                }
            }
        }
        
    }
}